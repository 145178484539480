import api from '../../../globals/client';
import config from '../../../config/app.config';

var promise;

export default class FacilityBookingService {
    show(id) {
        promise = api.client.get(config.end_point.facility.reservations.list + '/' + id);
        return promise;
    }

    update(data) {
        promise =  api.client.post(config.end_point.facility.reservations.update, data);
        return promise;
    }
}

export const facilityBookingService = new FacilityBookingService();
