import { reservation_status } from "@/utils/mockup";
import { facilityBookingService } from "@/services/facility/booking/booking.service";
import { alertService } from "@/services/alert/alert.service";

export default {
    data: () => {
        return {
            data: {
                flight_details: {},
            },
            dataReady: false,
            reservation_status: reservation_status,
            redirect_back_uri: {
                fc: "facility-flight-cancellation.index",
            },
        };
    },
    async mounted() {
        if (this.$route.params.id) {
            await this.show(this.$route.params.id);
        }
    },
    methods: {
        async show(id) {
            var response = await facilityBookingService.show(id);
            this.data = response.data;
            this.dataReady = true;
        },

        async enter() {
            var callback = async () => {
                await alertService.loading();
                await facilityBookingService
                    .update({
                        reservation_id: this.$route.params.id,
                        status: this.reservation_status.entered,
                    })
                    .then((response) => {
                        this.show(this.$route.params.id);
                        alertService.close();
                        alertService.success(
                            this.$t("facility.detail.entry-completed"),
                            this.$t("facility.detail.entered-success")
                        );
                    })
                    .catch((error) => {
                        this.error = error;
                        alertService.close();
                    });
            };
            alertService.confirmation(
                callback,
                this.$t("facility.global.confirmation"),
                this.$t("facility.detail.are-you-sure-enter")
            );
        },

        async exit() {
            var callback = async () => {
                await alertService.loading();
                await facilityBookingService
                    .update({
                        reservation_id: this.$route.params.id,
                        status: this.reservation_status.exited,
                    })
                    .then((response) => {
                        this.show(this.$route.params.id);
                        alertService.close();
                        alertService.success(
                            this.$t("facility.detail.exit-completed"),
                            this.$t("facility.detail.exited-success")
                        );
                    })
                    .catch((error) => {
                        this.error = error;
                        alertService.close();
                    });
            };
            alertService.confirmation(
                callback,
                this.$t("facility.global.confirmation"),
                this.$t("facility.detail.are-you-sure-exit")
            );
        },

        async back() {
            let r;
            if (this.$route.query.r) {
                r = {
                    name: this.redirect_back_uri[this.$route.query.r],
                };
                this.$router.push(r);
            } else {
                this.$router.go(-1)
            }
        },
    },
};
